// import AddStudent from "../pages/createStudent";
import ListStudents from "../pages/list-students";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'

export default function StudentsData(props){
  return(
    <>
      {/* <div className="col-md-6">
          <AddStudent batches1={props.batches1}/>
        </div> */}
   
        <div className="col-md-12">
        <ListStudents batches1={props.batches1}  />
        </div>
    </>
  )
} 