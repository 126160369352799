import React, { useEffect, useState } from "react"
import { useAuth, useUser } from '@clerk/clerk-react'
import createAxiosInstance from "../utility/utils"
import {Alert, Spinner, Container, Breadcrumb, Col, Row, Table, Button } from "react-bootstrap"
import { ExternalLink } from "lucide-react"
import { useNavigate } from 'react-router-dom'



export default function MySessions() {

  const navigate = useNavigate()

  const { isLoaded, isSignedIn, getToken } = useAuth()
  const { user } = useUser()
  const [sessions, setSessions] = useState([])
  const [selectedSession, setSelectedSession] = useState(null)
  const [attendanceData, setAttendanceData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const axiosInstance = createAxiosInstance(getToken)

  useEffect(() => {
    (async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    })
      ();
  }, [])


  useEffect(() => {
    if (isLoaded && isSignedIn) {
      const fetchSessions = async () => {
        try {
          const response = await axiosInstance.get(`/api/teacher/${user.id}/sessions`);
          setSessions(response.data.sessions);
        } catch (err) {
          console.log(err);
        }
      };

      fetchSessions();
    }
  }, [])

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    )
  }


  const handleSessionClick = async (session) => {
    try {
      const response = await axiosInstance.get(`/api/attendance/session/${session.sessionId}`);
      setSelectedSession(session.sessionId);
      setAttendanceData(response.data.attendanceRecords);

      // navigate to the next page
      navigate('/previous-attendance', {
        state: {
          batchName: session.batch.name,
          subjectName: session.subject.name,
          date: session.date,
          time: session.time,
          sessionId: session.sessionId,
          attendanceRecords: response.data.attendanceRecords
        }
      })
    } catch (err) {
      console.log(err);
    }
  }

  const temp = (
    <Container className="mt-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Apps</Breadcrumb.Item>
        <Breadcrumb.Item href="/batches">Attendance</Breadcrumb.Item>
        <Breadcrumb.Item active>Attendance History</Breadcrumb.Item>

      </Breadcrumb>

      <Row className="flex-column flex-md-row">
        <Col md={4} className="mb-4 mb-md-0">
          <div className="overflow-auto" style={{ maxHeight: '70vh' }}>
            <div className="list-group">
              {sessions.map(session => (
                <button
                  key={session.sessionId}
                  className="list-group-item list-group-item-action text-left"
                  onClick={() => handleSessionClick(session.sessionId)}
                >
                  <div className="font-semibold">{session.batch.name}</div>
                  <div className="text-sm text-gray-600">
                    {session.subject.name} - {session.date} {session.time}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </Col>

        {/* <Col md={8}>
            {selectedSession && (
              <div className="mt-4 mt-md-0">
                <h3 className="mb-3">Attendance for Session: {selectedSession}</h3>
                <div className="overflow-auto">
                  <Table striped bordered hover responsive>
                    <thead className="bg-gray-200">
                      <tr>
                        <th>Student Name</th>
                        <th>USN</th>
                        <th>Attendance Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendanceData.map(record => (
                        <tr key={record.student._id}>
                          <td>{record.student.name}</td>
                          <td>{record.student.usnNumber}</td>
                          <td>{record.attendanceStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </Col> */}
      </Row>
    </Container>
  );

  return (
    <Container className="mt-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Apps</Breadcrumb.Item>
        <Breadcrumb.Item href="/batches">Attendance</Breadcrumb.Item>
        <Breadcrumb.Item href="/teachers/mySession">Sessions</Breadcrumb.Item>
        <Breadcrumb.Item active> History</Breadcrumb.Item>

      </Breadcrumb>

      <Row>
        <Col>
          {/* <div className="overflow-auto border rounded-lg shadow-sm" style={{ maxHeight: '70vh' }}> */}
          {sessions.length===0 ? (
            <Alert variant="info" className="text-center">
              No records found
            </Alert>
          ):(
            <div className="border rounded-lg shadow-sm" >
            <Table hover responsive className="mb-0">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2">Batch</th>
                  <th className="px-4 py-2 d-none d-md-table-cell">Subject</th>
                  <th className="px-4 py-2 d-none d-md-table-cell">Date</th>
                  <th className="px-4 py-2">Time</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {sessions.map(session => (
                  <tr key={session.sessionId}>
                    <td className="px-4 py-2">
                      <div className="flex items-center">

                        {session.batch.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 d-none d-md-table-cell">
                      <div className="flex items-center">

                        {session.subject.name}
                      </div>
                    </td>
                    <td className="px-4 py-2 d-none d-md-table-cell">
                      <div className="flex items-center">

                        {session.date}
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex items-center">

                        {session.time}
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        // onClick={() => handleSessionClick(session.sessionId)}
                        onClick={() => {
                          // wait for handleSessionClick to finish
                          handleSessionClick(session)


                        }}
                        className="flex items-center"
                      >
                        <ExternalLink className="w-4 h-4 mr-1" />
                        <span className="d-none d-md-inline">View</span>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}