export default function batchesReducer(state, action){
  switch (action.type){
    case "SET_BATCHES":{
      return {...state, data:action.payload}
    }
    case "ADD_BATCHES":{
      return { ...state, data:[...state.data, action.payload]}
    }
    case "REMOVE_BATCHES":{
      return {...state, data: state.data.filter(ele=>ele._id!==action.payload)}
    }
    default:{
      return state
    }
  }
}