import React, { useState, useContext } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { AttendanceContext } from "../../contexts/attendances-context"
import {flexRender, getCoreRowModel, getFilteredRowModel, useReactTable, getPaginationRowModel} from '@tanstack/react-table'

export default function AttendanceList(props){

  const {attendances}=useContext(AttendanceContext)
  const [columnFilters, setColumnFilters]=useState([])
  const [filtering, setFiltering]=useState('')

  // const Filters 
  
  const columns=[
    {
      header: 'Sl. No.',
      accessorKey: 'serialNumber'
    },
    {
      header: 'Batch',
      accessorKey: 'batchName',
      // filterFn:'includesString'
    },
    {
      header: 'Student Name',
      accessorKey: 'student.name',
      filterFn:'includesString'
    },
    {
      header: 'USN',
      accessorKey: 'student.usnNumber',
      filterFn:'includesString'
    },
    {
      header: 'Subject',
      accessorKey: 'subject.name',
      filterFn:'includesString'
    },
    {
      header: 'Teacher', 
      accessorKey: 'teacher.name',
      filterFn:'includesString'
    },
    {
      header: 'Date',
      accessorKey: 'date',
      filterFn:'includesString'
    },
    {
      header: 'Time',
      accessorKey: 'time',
      filterFn:'includesString'
    },
    {
      header: 'Status',
      accessorKey: 'attendanceStatus',
      filterFn:'includesString'
    }
  ]

  const data = React.useMemo(() => {
    // console.log('Batches----', props.batches1)
    return (attendances?.data || []).map((ele, i) => {
      // console.log('Batch Name--', ele.batch.name)
      return {
        ...ele,
      serialNumber: i + 1,
      batchName: ele.batch.name || 'unknown',
      }
    })
  }, [attendances, props.batches1])

  const table= useReactTable({ 
    columns, 
    data,
    state:{
      columnFilters: columnFilters,
      globalFilter: filtering
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel:getFilteredRowModel(),
    onColumnFiltersChange:setColumnFilters,
    onGlobalFilterChange:setFiltering,
    getPaginationRowModel:getPaginationRowModel()
  })
  

  console.log(table.getHeaderGroups())

  // const handleSearch=(e)=>{
  //   const value = e.target.value;
  //   if (columnFilters.length === 0 || columnFilters[0].value !== value) {
  //     setColumnFilters(value ? [{ id: 'student.name', value }] : []);
  //   }
  // }
 
  return(
    <div className="container mt-4">
      <h2 className="mb-4">Attendance Data</h2>
      <div className="input-group input-group-sm mb-3" style={{width: '300px'}}>
      <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
        <input
          type="text"
          className="form-control" 
          aria-label="Sizing example input" 
          aria-describedby="inputGroup-sizing-sm"
          // placeholder="Search by Student Name"
          onChange={(e)=>setFiltering(e.target.value)}
        />
      </div>
      <div className="table">
        <table className="table table-responsive table-bordered table-striped" style={{textAlign: 'center'}}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header)=>(
                  <th key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <input 
                      type="text"
                      value={header.column.getFilterValue() ?? ''}
                      onChange={(e)=>header.column.setFilterValue(e.target.value)}
                      placeholder={`Filter ${header.column.columnDef.header}`}
                      className="form-control form-control-sm mt-2"
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            { table.getRowModel().rows.length===0 ?( 
              <tr>
                <td colSpan={columns.length}> No Record Found </td>
              </tr>
            ) : (table.getRowModel().rows.map(row=>(
              <tr key={row.id}>
                 {row.getVisibleCells().map(cell=>(
                  <td key={cell.id}>{flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}</td>
                 ))}
              </tr>
            )
            ))}
          </tbody>
        </table> 
        <div className="d-flex justify-content-center mb-3" >
          {/* <button className='btn btn-secondary mx-2' onClick={()=>table.setPageIndex(0)}>First Page</button> */}
          <button className='btn btn-secondary mx-2' disabled={!table.getCanPreviousPage()} onClick={()=>table.previousPage()}>Previous Page</button>
          <button className='btn btn-secondary mx-2' disabled={!table.getCanNextPage()} onClick={()=>table.nextPage()}>Next Page</button>
          {/* <button className='btn btn-secondary mx-2' onClick={()=>table.setPageIndex(table.getPageCount()-1)}>Last Page</button> */}
        </div>
      </div>
    </div>
  )
}

