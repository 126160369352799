import React, { useContext, useState, useRef} from "react";
import { StudentContext } from "../../contexts/studentContext";
import 'bootstrap/dist/css/bootstrap.min.css'
import { BatchContext } from "../../contexts/batches-context";
import Papa from 'papaparse'
import { useAuth } from '@clerk/clerk-react'
import createAxiosInstance from "../utility/utils";

export default function AddStudent(props){

  const {studentsDispatch}=useContext(StudentContext)
  const { batches } = useContext(BatchContext)
  console.log('batches-', batches)
  
  const [data, setData]=useState([])
  const [columns, setColumns]=useState([])
  const [values, setValues]=useState([])
  const fileInputRef=useRef(null)

  const [fileSelected, setFileSelected]=useState(false)

  const { getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)
  
  const [formErrors, setFormErrors]= useState({})
  const [formData, setFormData]=useState({
    name:'',
    usnNumber:'',
    email:'',
    batch:[]
  })

  const [serverErrors, setServerErrors]=useState('')
  
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting]=useState(false)
  
  const validate=()=>{
    const errors={}
    if(!formData.name.trim()){
      errors.name= 'Name is required'
    }
    if(!formData.usnNumber.trim()){
      errors.usnNumber='USN is required'
    }
    if(!formData.email.trim()){
      errors.email='Email is required'
    }
    if(!formData.batch.length===0){
      errors.batch='Batch is required'
    }
    return errors
  }
  
  const handleChange=(e)=>{
    const {name, value, options}=e.target
    
    if (name === "batch") {
      // For multi-select, handle batch array
      const selectedValues = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);

    
    // setFormData({
    //       ...formData,
    //       [name]:value
    //     })
    setFormData({
      ...formData,
      batch: selectedValues
    });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  }
  
  const handleFile=(e)=>{
    if(e.target.files.length>0){
      setFileSelected(true)
      Papa.parse(e.target.files[0],{
        header: true,
        skipEmptyLines: true,
        complete:(result)=>{
          const columnArray=[]
          const valueArray=[]
  
          result.data.map((ele)=>{
            columnArray.push(Object.keys(ele))
            valueArray.push(Object.values(ele))
          })
          setData(result.data)
          setColumns(columnArray[0])
          setValues(valueArray)
        }
      })
    }else{
      setFileSelected(false)
    }
  }
  
  const resetForm = () => {
    // Reset form data after successful submission
    setFormData({
        name:'',
        usnNumber:'',
        email:'',
        batch:[]
    });
    setServerErrors('')
}
  
  const handleSubmit=async(e)=>{
    e.preventDefault()
    const errors=validate()
    setFormErrors(errors)
    setSubmitted(true)
    
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true)
      try{
        triggerBatchesAction()
        console.log("submitting form data:", formData)
        const response= await axiosInstance.post(`/api/students`, formData)
        console.log(response.data)
        studentsDispatch({type:"ADD_STUDENT", payload:response.data})
        alert('Form submitted successfully')
        resetForm()
      }catch(err){
        const serverErrorMessage = err.response?.data?.errors || err.response?.data?.error|| 'Something went wrong'
        console.log('error-details',err.message)
        setServerErrors(serverErrorMessage)
      }finally{
        setIsSubmitting(false)
      }
      setSubmitted(false);
    }
  }

  const triggerBatchesAction = async () => {
    try {
      const response = await axiosInstance.get(`/api/batches`); 
      // batchesDispatch({ type: 'SET_BATCHES', payload: response.data });
      const batchData = response.data.reduce((acc, batch) => {
        acc[batch._id] = batch.name;
        return acc;
      }, {});
      props.setBatches(batchData) 
    } catch (err) {
      console.log(err.message);
    }
  }
  
  
  const uploadCsv=async()=>{
    try{
      const response=await axiosInstance.post(`/api/students/bulk-upload`,data)
      console.log(response.data)
      alert('csv data uploaded successfully')
      setData([])
      setColumns([])
      setValues([])
      if(fileInputRef.current){
        fileInputRef.current.value=null
      }
      setFileSelected(false)
      setServerErrors('')
    }catch(err){
      console.log(err.message)
      setServerErrors(err.response?.data?.errors || err.response?.data?.error || 'Something went wrong')
      alert(`CSV upload failed: ${err.message}`)
    }
  }
  
  return(
    <div className="container mt-4">
      <h2 className="mb-4">Add Student:</h2>
      {serverErrors && Array.isArray(serverErrors) ? (
        <div className="alert alert-danger">
          {serverErrors.map((error, index) => (
            <div key={index}>{error.msg || error.value || 'Unknown error'}</div>
          ))}
        </div>
      ) : (
        serverErrors && (
          <div className="alert alert-danger">
            {serverErrors}
          </div>
        )
      )}
      <div className="form-group mb-3">
          <label htmlFor="upload-csv">Upload CSV:</label>
          <input 
            type="file"
            className="form-control"
            name="upload-csv"
            accept=".csv"
            onChange={handleFile}
            style={{display:'block', margin:'1px auto' }}
            ref={fileInputRef}
          /><br/>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                {columns.map((col,i)=>(
                  <th key={i}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {values.map((vals,i)=>
               <tr key={i}>
                {vals.map((val,i)=>(
                    <td key={i}>{val}</td>
                ))}
               </tr>
              )}
            </tbody>
          </table>
          <div>
            <button 
              className="btn btn-primary btn-sm" 
              onClick={uploadCsv}
              disabled={!fileSelected}  
            >upload csv data</button>
          </div>
        </div>
        
      <form onSubmit={handleSubmit}>
        
        <div className="form-group mb-3">
          <label htmlFor="name">Student Name:</label>
          <input
            type="text"
            className={`form-control ${submitted && formErrors.name ? 'is-invalid' : ''}`}
            value={formData.name}
            name="name"
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}

        <div className="form-group mb-3">
          <label htmlFor="usnNumber">USN:</label>
          <input
            type="text"
            className={`form-control ${submitted && formErrors.usnNumber ? 'is-invalid' : ''}`}
            value={formData.usnNumber}
            name="usnNumber"
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.usnNumber && <div style={{ color: 'red' }}>{formErrors.email}</div>}

        <div className="form-group mb-3">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className={`form-control ${submitted && formErrors.email ? 'is-invalid' : ''}`}
            value={formData.email}
            name="email"
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.email && <div style={{ color: 'red' }}>{formErrors.email}</div>}

        <div className="form-group mb-3">
          <label htmlFor="batch">Batch:</label>
          <select
            value={formData.batch}
            onChange={handleChange}
            name="batch"
            className={`form-control ${submitted && formErrors.batch ? 'is-invalid' : ''}`}
            // multiple
          >
            <option value=''>Select Batch</option>
         
            {batches?.data.map((ele)=>{
              return (
                <option
                  key={ele._id}
                  value={ele._id}
                >{ele.name}</option>
              )

            })}
            
          </select>
        </div>
        {submitted && formErrors.batch && <div style={{ color: 'red' }}>{formErrors.batch}</div>}


        <button type='submit' className="btn btn-primary" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>

      </form>
    </div>
  )
}