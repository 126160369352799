import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useState, useEffect } from 'react';
import { TeachersContext } from '../../contexts/teachers-context';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import createAxiosInstance from '../utility/utils';
import {Spinner, Container, Breadcrumb } from "react-bootstrap"

export default function ListTeachers() {
  const { teachers, teachersDispatch } = useContext(TeachersContext);
  const { getToken } = useAuth();
  const axiosInstance = createAxiosInstance(getToken);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    })
      ();
  }, [])

  const handleRemove = async (id) => {
    const userConfirm = window.confirm('Are you sure?');
    if (userConfirm) {
      try {
        await axiosInstance.delete(`/api/users/${id}`);
        teachersDispatch({ type: 'REMOVE_TEACHER', payload: id });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleViewSessions = (teacherId) => {
    navigate(`/admin/teacher/${teacherId}/sessions`);
  };

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    );
  }

  return (
    <Container>
    <Breadcrumb>
      <Breadcrumb.Item href="/" >Apps</Breadcrumb.Item>
      <Breadcrumb.Item href="/admin-dashboard" >Home</Breadcrumb.Item>
    </Breadcrumb>
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-md-8'>
          <h2 className='mb-4'>Teachers List:</h2>
          <div className='table-responsive'>
            <table className='table table-striped table-bordered'>
              <thead className='thead-dark'>
                <tr>
                  <th>Sl. No.</th>
                  <th>Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {teachers?.data.length === 0 ? (
                  <tr>
                    <td colSpan="5">No Teachers found. Please Add Teachers</td>
                  </tr>
                ) : (
                  teachers?.data.map((teacher, i) => (
                    <tr key={teacher._id}>
                      <td>{i + 1}</td>
                      <td>{teacher.name}</td>
                      <td>{teacher.employeeId}</td>
                      <td>{teacher.email}</td>
                      <td>
                        <button
                          className='btn btn-info btn-sm mr-2'
                          onClick={() => handleViewSessions(teacher._id)}
                        >
                          View Sessions
                        </button>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => handleRemove(teacher._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </Container>
  );
}
