export default function studentsReducer(state={data:[]}, action){
  switch(action.type){
    case "ADD_STUDENT":{
      return {...state, data:[...state.data, action.payload]}
    }
    case "SET_STUDENTS":{
      return {...state, data:action.payload}
    }
    case "REMOVE_STUDENT":{
      return {...state, data: state.data.filter(ele => ele._id !== action.payload) }
    }
    case "SET_STUDENTS_BATCHWISE":{
      return {...state, data:action.payload}
    }
    default:{
      return state
    }
  }
}