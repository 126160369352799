import axios from 'axios'
import { host } from '../../configure'

const createAxiosInstance=(getToken)=>{
    const axiosInstance=axios.create({
        baseURL:host,
        timeout:15000,
    });
    
    axiosInstance.interceptors.request.use(
        async(config)=>{
            const token= await getToken()
            if(token){
                config.headers['Authorization']=`Bearer ${token}`
            }
            return config
        },
        (error)=>{
            return Promise.reject(error)
        }
    )
    return axiosInstance
}
export default createAxiosInstance
