import axios from 'axios';
import { useState, useEffect } from 'react';
import { host } from '../../configure';

const HealthCheck = () => {
  const [healthStatus, setHealthStatus] = useState(null); // No type annotations needed

  useEffect(() => {
    async function checkHealth() {
      try {
        const response = await axios.get(`${host}/health`);
        if (response.status === 200) {
          setHealthStatus(response.data.status || 'healthy');
        } else {
          setHealthStatus('unhealthy');
        }
      } catch (error) {
        console.error('Error fetching health status:', error);
        setHealthStatus('error');
      }
    }

    checkHealth();
  }, []);

  return (
    <div>
      <h1>Health Check</h1>
      <p>Server status: {healthStatus}</p>
    </div>
  );
};

export default HealthCheck;
