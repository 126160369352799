export default function attendanceReducer(state= { data: [] }, action){
  switch(action.type){
    case "ADD_ATTENDANCE":{
      return { ...state, data:[...state.data, action.payload]}
    }
    case "SET_ATTENDANCE":{
      return { ...state, data:action.payload}
    }
    case "SET_EACH_STUDENT_ATTENDANCE":{
      return { ...state, data:action.payload}
    }
    default:{
      return state
    }
  }
}