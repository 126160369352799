import { useContext, useState, useRef } from "react"
import Papa from 'papaparse'
import 'bootstrap/dist/css/bootstrap.min.css'
import { SubjectContext } from "../../contexts/subjects-context"
import { BatchContext } from "../../contexts/batches-context"
import { useAuth } from "@clerk/clerk-react"
import createAxiosInstance from "../utility/utils"
import {Alert} from 'react-bootstrap'

export default function AddSubject(){
  const {batches}=useContext(BatchContext)
  const {subjectsDispatch}=useContext(SubjectContext)
  const [submitted, setSubmitted]=useState(false)
  const [formData, setFormData]=useState({
    name:'',
    batch:[]
  })
  const [isSubmitting, setIsSubmitting]=useState(false)

  const [errorMessage, setErrorMessage]=useState('')

  const fileInputRef=useRef(null)
  const [columns, setColumns]=useState([])
  const [values, setValues]=useState([])
  const [fileSelected, setFileSelected]=useState(false)
  const [data, setData]=useState([])

  const { getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)

  const [formErrors, setFormErrors]=useState({})

  const validate=()=>{
    const errors={}
    if(!formData.name.trim()){
      errors.name='Name is required'
    }
    if(!formData.batch.length===0){
      errors.batch='Batch is required'
    }
    return errors
  }

  const resetForm=()=>{
    setFormData({
      name:'',
      batch:[]
    })
    setSubmitted(false)
  }

  const handleFile = (e) => {
    if (e.target.files.length > 0) {
      setFileSelected(true);
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const parsedData = result.data.map((subject) => ({
            ...subject,
            Batches: subject.Batches.split(',').map((batch) => batch.trim())
          }));
  
          setData(parsedData);
          setColumns(Object.keys(parsedData[0] || {}));
          setValues(parsedData.map(Object.values));
        },
        error: (error) => {
          console.error("Error parsing CSV: ", error);
        }
      });
    } else {
      setFileSelected(false);
    }
  };
  

  const uploadCsv = async () => {
    if (data.length === 0) {
      alert("No data to upload");
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/subjects/bulk-upload`, data);
      console.log(response.data);
      alert('CSV data uploaded successfully');
      resetForm();
      setData([]);
      setColumns([]);
      setValues([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setFileSelected(false);
    } catch (err) {
      console.error("Upload error: ", err.response?.data?.errors);
      if (Array.isArray(err.response?.data?.errors)) {
        setErrorMessage(err.response.data.errors.map(error => `${error.msg} (Field: ${error.param})`).join(', '));
      } else {
        setErrorMessage('Failed to upload CSV data. Please try again.');
      }
      alert("Failed to upload CSV data. Please try again.");
    }
  };


  const handleSubmit=async(e)=>{
    e.preventDefault()
    const errors=validate()
    setFormErrors(errors)
    setSubmitted(true)

    if(Object.keys(errors).length===0){
      setIsSubmitting(true)
      try{
        const response=await axiosInstance.post(`/api/subjects`,formData)
        console.log('subject-', response.data)
        subjectsDispatch({type:'ADD_SUBJECT', payload:response.data})
        alert('added subject successfully')
        resetForm()
      }catch(err){
        console.log(err)
      }finally{
        setIsSubmitting(false)
      }
    }

  }

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      if (checked) {
        return {
          ...prevData,
          batch: [...prevData.batch, value]
        };
      } else {
        return {
          ...prevData,
          batch: prevData.batch.filter((batchId) => batchId !== value)
        };
      }
    });
  };

  return(
    <div className="container mt-4">
      <h2 className="mb-4">Add Subject:</h2>
      <div>
        {errorMessage && (
          <Alert variant='danger'>{errorMessage}</Alert> 
        )}
        <div className="form-group mb-3">
          <label htmlFor="upload-csv">Upload Csv:</label>
          <input 
            type="file"
            className="form-control"
            name="upload-csv"
            onChange={handleFile}
            style={{display:'block', margin:'1px auto'}}
            accept=".csv"
          /><br/>
          <table className="table table-responsive table-bordered">
            <thead>
              <tr>
              {columns.map((col,i)=>(
                <th key={i}>{col}</th>
              ))}
              </tr>
            </thead>
            <tbody>
              {values.map((vals, i)=>(
                <tr key={i}>{
                  vals.map((val, i)=>(
                    <td key={i}>{val}</td>
                  ))  
                }</tr>
              ))}
            </tbody>
          </table>
          <div>
            <button 
              className="btn btn-primary btn-sm" 
              onClick={uploadCsv}
              disabled={!fileSelected}
            >upload csv data
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="name">Subject Name:</label>
            <input 
              type="text"
              name="name"
              className={`form-control ${submitted && formErrors.name ? 'is-invalid' : ''}`}
              value={formData.name}
              // onChange={handleChange}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
          {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}


          <div className="form-group mb-3">
            <label>Batches:</label>
            {batches?.data.length === 0 ? (
              <div>No Batch found</div>
            ) : (
              batches?.data.map((ele) => (
                <div key={ele._id} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`batch-${ele._id}`}
                    value={ele._id}
                    checked={formData.batch.includes(ele._id)}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor={`batch-${ele._id}`}>
                    {ele.name}
                  </label>
                </div>
              ))
            )}
          </div>
          {submitted && formErrors.batch && <div style={{ color: 'red' }}>{formErrors.batch}</div>}


          <button className="btn btn-primary" type="submit" disabled={isSubmitting || Object.keys(formErrors).length>0}>{isSubmitting ? 'Submitting...' : "Submit"}</button>
        </form>
      </div>
    </div>
  )
}