// import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import {format} from 'date-fns'
import { BatchContext } from '../../contexts/batches-context'
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from 'react-bootstrap/Form'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SubjectContext } from '../../contexts/subjects-context';
import { AttendanceContext } from '../../contexts/attendances-context';
import { useAuth, useUser } from '@clerk/clerk-react'
import createAxiosInstance from '../utility/utils'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import {Alert, Spinner, Button } from 'react-bootstrap'
import { CalendarCheck, ChevronsRight } from 'lucide-react'


export default function ListBatches(props){
  // const {students, studentsDispatch}=useContext(StudentContext)
  const [studentsBatchwise, setStudentBatchwise]=useState([])
  // const {attendances,attendancesDispatch}=useContext(AttendanceContext)
  // const [attendances,attendancesDispatch]=useReducer(attendanceReducer)
  const {batches, batchesDispatch}=useContext(BatchContext)
  const {subjects, subjectsDispatch}=useContext(SubjectContext)
  const [selectedBatchId, setSelectedBatchId]=useState('')
  const [selectedBatchName, setSelectedBatchName]=useState('')
  const [selectedSubject, setSelectedSubject]=useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modal, setModal]=useState(false)
  const [isLoading, setIsLoading]=useState(true)
  const [errorMessage, setErrorMessage] = useState(null)


  const { isLoaded, isSignedIn, getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)
  
  const {user}=useUser()
  console.log('user obj-', user)

  const [date, setDate]=useState(format(new Date(), 'yyyy-MM-dd', { timeZone: 'Asia/Kolkata' }))
  const [time, setTime]=useState(format(new Date(), 'HH:mm', { timeZone: 'Asia/Kolkata' }))
  const [attendance, setAttendance]=useState({})

  const navigate=useNavigate()

  useEffect(()=>{
    (async()=>{
      try{
        await new Promise(resolve=>setTimeout(resolve, 1000))
        setIsLoading(false)
      }catch(err){
        console.log(err)
      }
    })();
  },[])

  
  
  useEffect(()=>{
    if(isLoaded && isSignedIn){
      console.log('User---', user)

      // const now= new Date()
      // setDate(format(now, 'yyyy-MM-dd'))
      // setTime(now.toISOString().slice(11,16))
    }
  },[isLoaded, isSignedIn, user])

  if(isLoading){
    return(
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    )
  }

  const handleRemove=async(id)=>{
    const userConfirm=window.confirm('Are you sure?')
    if(userConfirm){
      try{
        await axiosInstance.delete(`/api/batches/${id}`)
        batchesDispatch({type:"REMOVE_BATCHES", payload: id})
      }catch(err){
        console.log(err)
      }
    }
  }
  const toggle=()=>{
    setModal(!modal)
    setErrorMessage(null)
  }
  const handleShow=async(batchId, )=>{
    if (!isLoaded || !user) return;
    setSelectedBatchId(batchId);
    const batch=batches.data.find((ele)=>ele._id===batchId)
    setSelectedBatchName(batch ? batch.name : '')
    toggle();
    try {
      const response = await axiosInstance.get(`/api/students/batch-wise/${batchId}`);
      const initialAttendance = response.data.reduce((acc, student) => {
        acc[student._id] = 'present'; // default to 'present'
        return acc;
      }, {});
      const response1=await axiosInstance.get(`/api/subjects/batch-wise/${batchId}`)
      console.log(response1.data)
      subjectsDispatch({type:'SET_SUBJECT_BATCHWISE', payload: response1.data})
      setStudentBatchwise(response.data);
      setAttendance(initialAttendance)
    } catch (err) {
      console.log(err.message);
    }
  }

  const handleAttendanceChange = (studentId, status) => {
    setAttendance({
      ...attendance,
      [studentId]: status,
    });
  };

  const handleAttendanceSubmit = async () => {
    const selectedDate=new Date(date).setHours(0,0,0,0)
    const today=new Date().setHours(0,0,0,0)

    if(selectedDate !== today){
      alert('You are not allowed to take attendance for past or future dates')
      return
    }

    setIsSubmitting(true)
    
    try{
      const students=[]
      studentsBatchwise.map(student => students.push({
        student: student._id,
        attendanceStatus: attendance[student._id], // 'present' or 'absent'
      }));

      const payload={
        teacher: user && user.publicMetadata? user.publicMetadata?.employeeId : null, 
        batch: selectedBatchId,
        subject: selectedSubject,  
        date: date ? format(new Date(),'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        // date: date,
        time: time || new Date().toISOString().slice(11, 16), // Example: 
        students:students
      }
      console.log('payload-', payload)
      
      const response = await axiosInstance.post('/api/attendance',payload );
      const sessionId=response.data.sessionId
      console.log('Submitting attendance:', response.data);

      const attendanceResponse=await axiosInstance.get(`/api/attendance?sessionId=${sessionId}`)
      const attendanceRecords=attendanceResponse.data
      // attendancesDispatch({type:'ADD_ATTENDANCE', payload: response.data})
      navigate('/previous-attendance',{
        state:{
          batchName:selectedBatchName,
          subjectName:subjects?.data.find(subject => subject._id === selectedSubject)?.name,
          date, 
          time,
          sessionId,
          attendanceRecords
        }
      })
      toggle()
    }catch(err){
      console.log('err.response-', err.response.data.errors[0].msg)
      // setErrorMessage('Failed to submit attendance. Please try again.')
      setErrorMessage(err.response.data.errors[0].msg)
      if (err.response && err.response.data && err.response.data.errors[0].msg) {
        setErrorMessage(err.response.data.errors[0].msg);
    } else {
        setErrorMessage('Failed to submit attendance. Please try again.');
    }
    }finally{
      setIsSubmitting(false)
    }
    
  };

  const handleDateChange=(e)=>{
    const { value } = e.target
    setDate(value)
  }

  const handleTimeChange=(e)=>{
    const { value } = e.target
    setTime(value)
  }

  const handleChange=(e)=>{
    const {value}= e.target
    setSelectedSubject(value)
  }

  return (<>
    <Container className="mt-4">
    <Breadcrumb>
      <Breadcrumb.Item href="/">Apps</Breadcrumb.Item>
      <Breadcrumb.Item active>Attendance</Breadcrumb.Item>
      
    </Breadcrumb>
      {/* <Row className="text-center mb-2">
            <Col>
            <h2 className='mb-4'>Attendance</h2>
            </Col>
        </Row> */}
        <Row className="text-center mb-2">
          <Col>
        <img 
                        src={"/images/undraw_Booking_re_gw4j.png"} 
                        // alt={`${app.app.name} logo`} 
                        style={{ width: '250px', height: '200px', objectFit: 'contain' }} 
                        />
                        </Col>
                        </Row>

        {/* <Row className="text-center mb-2">
            <Col>
            <Button  to='/add-batch'  variant='outline-secondary'>View Attendance</Button>
            </Col>
           
        </Row>
        <Row className="text-center mb-0">
           
            <Col>
            <h6 className='mb-4'>Or</h6>
            </Col>
            
        </Row> */}
        <Row className="d-flex align-items-center justify-content-center text-center mb-2" style={{ minHeight: '100px' }}>
          <Col className="d-flex flex-column align-items-center justify-content-center">
          {user && user.publicMetadata && user.publicMetadata.role==='teacher' && (
            <>
            <Button variant='outline-dark' size='sm' className="d-flex align-items-center mb-3"
            onClick={e=>{
              navigate('/teachers/mySession')
            }}
            >
                <CalendarCheck size={16} className="me-2" />
                <span>Click to View Attendance History</span>
              </Button>
              <p>(Or)</p>
            </>
          )
          }
            <h6 className='mb-4'>
              Pick a batch to take attendance or
            </h6>
            
          </Col>
        </Row>
    {/* <Row className="d-flex align-items-center justify-content-center text-center mb-2" style={{ minHeight: '50px' }}>
      <Col className="d-flex align-items-center justify-content-center">
      <Button variant='outline-dark' size='sm' className="d-flex align-items-center">
          <CalendarCheck size={16} className="me-2" />
          <span>View Attendance History</span>
        </Button>
        {' '}
        <h6 className='mb-0 me-3'>
        &nbsp;&nbsp;(Or) Pick a batch to take attendance
        </h6>
        
      </Col>
    </Row> */}     
        <Row className="justify-content-center">
         { batches?.data.map((batch, i)=>(<>
          <Col key={1} xs={9} sm={6} md={4} lg={3} className="mb-4" style={{ maxWidth: '280px' }}>
            <Card className=" shadow-sm"
              onClick={()=>{
                handleShow(batch._id)
              }}
              >
                 {/* <Card.Img variant="top" src="/images/undraw_Reading_book_re_kqpk.png" /> */}
              <Card.Body className="d-flex flex-column justify-content-between">
                <Card.Title className="text-center">
                  Batch : <i>{batch.name}</i>
                  
                </Card.Title>
                <Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="light" size="sm">
                    <ChevronsRight size={16} />
                  </Button>
                  
                </div>
              </Card.Text>

              </Card.Body>
              
            </Card>
          </Col>
         </>))}
        </Row>
    </Container>

                  
    <Modal size='xl' isOpen={modal} toggle={toggle} backdrop='static'>
            <ModalHeader toggle={toggle}> Attendance for Batch:  <i><u>{selectedBatchName}</u></i>  </ModalHeader>
            <ModalBody>
              {errorMessage && (
                <Alert variant='danger'>{errorMessage}</Alert> 
              )}
              {user && user.publicMetadata && user.publicMetadata.role==='teacher' && (
                <>
                  <div>
                  <label htmlFor='date'>For Date: {date}</label>
                  {/* <label htmlFor='date'>Date</label>
                  <input 
                    type='date'
                    name='date'
                    value={date}
                    class="form-control" 
                    onChange={handleDateChange}
                  /> */}
                </div>

                <div>
                  <label htmlFor='time'>Time</label>
                  <input 
                    type='time'
                    class="form-control" 
                    name='time'
                    value={time}
                    onChange={handleTimeChange}
                  />
                </div>
                <div>
                  <label htmlFor='subject'>Subject:</label>
                  <Form.Select
                    name='subject'
                    className="form-control" 
                    value={selectedSubject}
                    onChange={handleChange}
                  >
                    <option value=''>Select Subject</option>
                    {subjects?.data.length==0 ? <option>No Subjects Assigned</option> : subjects?.data.map((subject)=>{
                      return(
                        <option key={subject._id} value={subject._id}>{subject.name}</option>
                      )
                    })}
                  </Form.Select>
                </div>
                </>
              )}
              <br/>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className='thead-dark'>
                    <tr>
                      {/* <th>Sl. No.</th> */}
                      <th>Student Name</th>
                      {/* <th>USN</th> */}
                      {/* <th>Email</th> */}
                      {user && user.publicMetadata && user.publicMetadata.role==='teacher' && (
                        <>
                          <th>Attendance</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {studentsBatchwise.length === 0 ? (
                      <tr>
                        <td colSpan="4">No students found for this batch</td>
                      </tr>
                    ) : (
                      studentsBatchwise.map((ele, i) => (
                        <tr key={ele._id}>
                          {/* <td>{i + 1}</td> */}
                          <td>{ele.name}</td>
                          {user && user.publicMetadata && user.publicMetadata.role==='teacher' && 
                          (<td>
                             <Form.Check 
                              type='switch'
                              id={`attendance-toggle-${ele._id}`}
                              label={attendance[ele._id]==='present' ? 'Present' : 'Absent'}
                              checked={attendance[ele._id]==='present'}
                              onChange={()=>handleAttendanceChange(ele._id, attendance[ele._id]==='present' ? 'absent' : 'present')}
                             />
                          </td>)
                          }
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {user && user.publicMetadata && user.publicMetadata.role === 'teacher' && (
              <button className='btn btn-primary' onClick={handleAttendanceSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit Attendance'}
              </button>
              )}
            </ModalBody>
          </Modal>
</>)
}