import React, { useState, useReducer, useEffect} from 'react'
import { Route, Routes } from 'react-router-dom'
import { SignedIn, SignedOut, SignInButton} from '@clerk/clerk-react'
import studentsReducer from './reducers/students-reducer'
import batchesReducer from './reducers/batches-reducer'
import teachersReducer from './reducers/teachers-reducer'
import subjectsReducer from './reducers/subjects-reducer'
import attendanceReducer from './reducers/attendance-reducer'
import { StudentContext } from './contexts/studentContext';
import AdminsDashboard from "./components/dashboard/admin-dashboard";
import TeachersDashboard from "./components/dashboard/teachers-dashboard";
import { BatchContext } from './contexts/batches-context';
import { TeachersContext } from './contexts/teachers-context'
import { SubjectContext } from './contexts/subjects-context'
import { AttendanceContext } from './contexts/attendances-context'
// import Header from './components/headers/headers'
import StudentsData from './components/pages/students-data'
import ListSubjects from './components/pages/list-subjects'
import ListBatches from './components/pages/list-batches'
import ListTeachers from './components/pages/list-teachers'
import AttendanceList from './components/pages/listing-attendance'
import PastAttendance from './components/pages/view-current-attendance'
import MySessions from './components/pages/view-myAttendance'
import TeacherSessions from './components/pages/Teachers-sessions'
import HealthCheck from './components/pages/health'
import { useAuth } from '@clerk/clerk-react'
import createAxiosInstance from './components/utility/utils'
import { Container,Row,Col } from 'react-bootstrap';
import NavHeader from './components/headers/nav-header'
import AppLauncher from './components/dashboard/apps'
import { H } from 'highlight.run';
import AttendanceRecord from './components/pages/Teacher-AttendanceTaken'
import StudentAttendanceView from './components/pages/Student-AttendanceView'


function App() {
  const [students, studentsDispatch]=useReducer(studentsReducer)
  const [batches, batchesDispatch]=useReducer(batchesReducer)
  const [batches1, setBatches]=useState({})
  const [teachers, teachersDispatch]=useReducer(teachersReducer)
  const [subjects, subjectsDispatch]=useReducer(subjectsReducer)
  const [attendances,attendancesDispatch]=useReducer(attendanceReducer)
  const { getToken , user } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)

  

  useEffect(() => {
    if (user) {
      H.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.emailAddresses[0].emailAddress,
        role: user.publicMetadata.role,  
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await axiosInstance.get(`/api/batches`)
        const batchData = response.data.reduce((acc, batch) => {
          acc[batch._id] = batch.name
          return acc
        }, {})
        setBatches(batchData)
      } catch (err) {
        console.log(err.message);
      }
    }
    fetchBatches();
  }, [])

  useEffect(()=>{
    const fetchStudents=async()=>{
      try{
        const response=await axiosInstance.get(`/api/students`)
        // console.log('students123-',response.data)
        studentsDispatch({type:"SET_STUDENTS", payload:response.data})
      }catch(err){
        console.log(err)
      }
    }
    fetchStudents();

  },[])

  useEffect(()=>{
    (async ()=>{
      try{
        const response=await axiosInstance.get(`/api/batches`)
        batchesDispatch({type:'SET_BATCHES', payload: response.data})
      }catch(err){
        console.log(err.message)
      }
    })();
  },[])

  useEffect(()=>{
    (async()=>{
      try{
        const response=await axiosInstance.get(`/api/users`)
        // console.log(response.data)
        teachersDispatch({type:"SET_TEACHERS" , payload: response.data})
      }catch(err){
        console.log(err)
      }
    })();    
  },[])

  useEffect(()=>{
    (async()=>{
      try{
        
        const response=await axiosInstance.get(`/api/subjects`)  
        console.log('subjects',response.data)
        subjectsDispatch({type:'SET_SUBJECTS', payload:response.data})
      }catch(err){
        console.log(err)
      }
    })();

  },[])

  useEffect(()=>{
    (async()=>{
      try{
        const response=await axiosInstance.get(`/api/attendance`)
        // console.log('attendance list-', response.data)
        attendancesDispatch({type:'SET_ATTENDANCE', payload: response.data})
      }catch(err){
        console.log(err)
      }
    })();
  },[])



  return (
    <StudentContext.Provider value={{students, studentsDispatch}}>
      <BatchContext.Provider value={{batches, batchesDispatch}}>
        <TeachersContext.Provider value={{teachers, teachersDispatch}}>
          <SubjectContext.Provider value={{subjects, subjectsDispatch}} >
            <AttendanceContext.Provider value={{attendances,attendancesDispatch}}>
              <div className="App">
                <NavHeader/>
                <header>
                  <SignedOut>
                     
                  
                  <Container className="mt-5">
            <Row className="text-center mb-4">
                <Col>
                <img 
                        src="/images/undraw_Login_re_4vu2.png"
                        // alt={`${app.app.name} logo`} 
                        style={{ width: '200px', height: '300px', objectFit: 'contain' }} 
                        />
                    <p>Click signin to continue.</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
            <Col className="text-center">
            <SignInButton className="btn btn-light" mode='redirect'/>
            </Col>
            </Row>
        </Container>
                  </SignedOut>
                </header>
                <Routes>
                <Route 
                    path='/apps'
                    // path='/' 
                    element={
                      <SignedIn>
                       <AppLauncher />
                      </SignedIn>
                    } 
                  />
                  <Route 
                    path='/admin-dashboard'
                    // path='/' 
                    element={
                      <SignedIn>
                        <AdminsDashboard batches1={batches1} />
                      </SignedIn>
                    } 
                  />
                  <Route 
                    path='/teacher-dashboard' 
                    element={
                      <SignedIn>
                        <TeachersDashboard batches1={batches1}/>
                      </SignedIn> 
                    } 
                  />
                  <Route 
                    path='/students-data' 
                    element={
                      <SignedIn>
                        <StudentsData batches1={batches1} /> 
                      </SignedIn>
                    } 
                  />
                  <Route 
                    path='/subjects' 
                    element={
                      <SignedIn>
                        <ListSubjects batches1={batches1} />
                      </SignedIn>
                    } 
                  />
                  <Route 
                    path='/batches' 
                    element={
                      <SignedIn>
                        <ListBatches batches1={batches1}/>
                      </SignedIn>
                    } 
                  />
                  <Route 
                    path='/previous-attendance'
                    element={
                      <SignedIn>
                        <PastAttendance />
                      </SignedIn>
                    }
                  />
                  <Route 
                    path='/attendance-records'
                    element={
                      <SignedIn>
                        <AttendanceRecord />
                      </SignedIn>
                    }
                  />
                  <Route 
                    path='/teachers/mySession'
                    element={
                      <SignedIn>
                        <MySessions />
                      </SignedIn>
                    }
                  />
                  <Route 
                    path='/teachers' 
                    element={
                      <SignedIn>
                        <ListTeachers /> 
                      </SignedIn>
                    } 
                  />
                  <Route
                    path='admin/teacher/:teacherId/sessions'
                    element={
                      <SignedIn>
                        <TeacherSessions />
                      </SignedIn>
                    }
                  />
                  <Route 
                    path='/attendance-history' 
                    element={
                      <SignedIn>
                        <AttendanceList batches1={batches1} />
                      </SignedIn>
                    }   
                  />
                  <Route 
                    path='/health'
                    element={<HealthCheck/>}  
                  />
                  <Route 
                    path='/attendance/:studentId'
                    element={
                      <SignedIn>
                        <StudentAttendanceView />
                      </SignedIn>
                    }
                  />
                </Routes>
              </div>
            </AttendanceContext.Provider>
          </SubjectContext.Provider>
        </TeachersContext.Provider>
      </BatchContext.Provider>
    </StudentContext.Provider>
  );
}

export default App;
