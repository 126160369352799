import { useEffect, useState, useMemo } from "react";
import { Container, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuth } from '@clerk/clerk-react';
import createAxiosInstance from "../utility/utils";

export default function StudentAttendanceView() {
    const { getToken } = useAuth();
    const axiosInstance = useMemo(() => createAxiosInstance(getToken), [getToken]);
    
    const [attendances, setAttendances] = useState([]);
    const { studentId } = useParams();

    useEffect(() => {
        const fetchAttendance = async (studentId) => {
            try {
                const response = await axiosInstance.get(`/api/attendance/${studentId}`);
                console.log('stu_attend', response.data);
                setAttendances(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        if (studentId) {
            fetchAttendance(studentId);
        }
    }, [studentId, axiosInstance]); 

    return (
        <Container>
            <div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Subject Name</th>
                            <th>Teacher Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Attendance Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendances.length > 0 ? (
                            attendances.map((ele, i) => {
                                const isSameSubject = i > 0 && attendances[i - 1].subject._id === ele.subject._id;

                                return (
                                    <tr key={i}>
                                        {/* Only show the subject name if it is not the same as the previous row */}
                                        {!isSameSubject ? (
                                            <td rowSpan={attendances.filter(item => item.subject._id === ele.subject._id).length}>
                                                {ele.subject.name} {/* Ensure this is the name property */}
                                            </td>
                                        ) : null}
                                        <td>{ele.teacher.name}</td> {/* Ensure this is the name property */}
                                        <td>{ele.date}</td>
                                        <td>{ele.time}</td>
                                        <td>{ele.attendanceStatus}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="5">No attendance records found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
}
