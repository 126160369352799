import { useContext, useState, useRef } from "react"
import { TeachersContext } from "../../contexts/teachers-context"
import 'bootstrap/dist/css/bootstrap.min.css'
import Papa from 'papaparse'
import { useAuth } from '@clerk/clerk-react'
import createAxiosInstance from "../utility/utils"

export default function AddTeacher(){
  const {teachersDispatch}=useContext(TeachersContext)
  const [formData, setFormData]= useState({
    name:'',
    employeeId:'',
    email:'',
    password:'',
    role:''
  })
  const [data, setData]=useState([])
  const [submitted, setSubmitted]=useState(false)
  const [formErrors, setFormErrors]=useState({})
  const [columns, setColumns]=useState([])
  const [values, setValues]=useState([])
  const fileInputRef=useRef(null)
  const [fileSelected, setFileSelected]=useState(false)
  const [serverErrors, setServerErrors]=useState('')
  const [isSubmitting, setIsSubmitting]=useState(false)
  
  const { getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)
  

  const validate=()=>{
    const errors={}
    if(!formData.name.trim()){
      errors.name='Name is required'
    }
    if(!formData.employeeId.trim()){
      errors.employeeId='Employee Id is required'
    }
    if(!formData.email.trim()){
      errors.email='Email is required'
    }
    if(!formData.password.trim()){
      errors.password='Password is required'
    }
    if(!formData.role.trim()){
      errors.role='Role is required'
    }
    return errors
  }

  const resetForm=()=>{
    setFormData({
      name:'',
      employeeId:'',
      email:'',
      password:'',
      role:''
    })
    setServerErrors('')
  }

  const handleFile=(e)=>{
    if(e.target.files.length>0){
      setFileSelected(true)
      Papa.parse(e.target.files[0],{
        header: true,
        skipEmptyLines: true,
        complete:(result)=>{
          const columnArray=[]
          const valueArray=[]
  
          result.data.map((ele)=>{
            columnArray.push(Object.keys(ele))
            valueArray.push(Object.values(ele))
          })
          setData(result.data)
          setColumns(columnArray[0])
          setValues(valueArray)
        }
      })
    }else{
      setFileSelected(false)
    }
  }

  const handleChange=(e)=>{
    const {name, value}=e.target
    setFormData({
      ...formData,
      [name]:value
    })
  }

  const uploadCsv=async()=>{
    try{
      const token=await getToken()
      const response=await axiosInstance.post(`/api/users/bulk-upload`,data)
      console.log(response.data)
      alert('csv data uploaded successfully')
      setData([])
      setColumns([])
      setValues([])
      if(fileInputRef.current){
        fileInputRef.current.value=null
      }
      setFileSelected(false)
      setServerErrors('')
    }catch(err){
      console.log(err.message)
      setServerErrors(err.response?.data?.errors || err.response?.data?.error || 'Something went wrong')
      alert(`CSV upload failed: ${err.message}`)
    }
  }

  const handleSubmit=async(e)=>{
    e.preventDefault()
    const errors=validate()
    setFormErrors(errors)
    setSubmitted(true)

    if(Object.keys(errors).length===0){
      setIsSubmitting(true)
      try{
        const response=await axiosInstance.post(`/api/users/create`, formData)
        console.log("createTeacher",response.data)
        teachersDispatch({type:'ADD_TEACHER', payload: response.data})
        alert('teacher added successfully')
        resetForm()
      }catch(err){
        console.log(err)
      }finally{
        setIsSubmitting(false)
      }
    }

  }

  return(
    <div className="container mt-4">
      <h2 className="mb-4">Add Teacher:</h2>
      {serverErrors && Array.isArray(serverErrors) ? (
        <div className="alert alert-danger">
          {serverErrors.map((error, index) => (
            <div key={index}>{error.msg || error.value || 'Unknown error'}</div>
          ))}
        </div>
      ) : (
        serverErrors && (
          <div className="alert alert-danger">
            {serverErrors}
          </div>
        )
      )}
      <div className="form-group mb-3">
        <label htmlFor="upload-csv">Upload CSV:</label>
        <input 
          type="file"
          className="form-control"
          name="upload-csv"
          accept=".csv"
          onChange={handleFile}
          style={{display:'block', margin:'1px auto' }}
          ref={fileInputRef}
        /><br/>
        <table className="table table-bordered table-responsive">
          <thead>
            <tr>
              {columns.map((col,i)=>(
                <th key={i}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.map((vals,i)=>
              <tr key={i}>
              {vals.map((val,i)=>(
                  <td key={i}>{val}</td>
              ))}
              </tr>
            )}
          </tbody>
        </table>
        <div>
          <button 
            className="btn btn-primary btn-sm" 
            onClick={uploadCsv}
            disabled={!fileSelected}  
          >upload csv data</button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="name">Teacher Name:</label>
          <input 
            type="text"
            name="name"
            className={`form-control ${submitted && formErrors.name ? 'is-invalid' : ''}`}
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}

        <div className="form-group mb-3">
          <label htmlFor="employeeId">Employee Id:</label>
          <input 
            type="text"
            name="employeeId"
            className={`form-control ${submitted && formErrors.employeeId ? 'is-invalid' : ''}`}
            value={formData.employeeId}
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}

        <div className="form-group mb-3">
          <label htmlFor="email">Email:</label>
          <input 
            type="email"
            name="email"
            className={`form-control ${submitted && formErrors.email ? 'is-invalid' : ''}`}
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.email && <div style={{color:'red'}}>Email is required</div>}

        <div className="form-group mb-3">
          <label htmlFor="password">Password</label>
          <input 
            type="password"
            name="password"
            className={`form-control ${submitted && formErrors.password ? 'is-invalid' : ''}`}
            value={formData.password}
            onChange={handleChange}
          />
        </div> 
        {submitted && formErrors.password && <div style={{color:'red'}}>Password is required</div>}

        <div className="form-group mb-3">
          <label htmlFor="role" className="form-label">Role</label>
            <div>
             
              <input 
                type="radio"
                name="role"
                value='teacher'
                checked={formData.role==='teacher'}
                onChange={handleChange}
                className="form-check-input"
                id='teacher'
              />
               <label htmlFor="teacher">Teacher</label>
            </div>

        </div>

        <button className="btn btn-primary" type="submit" disabled={isSubmitting || Object.keys(formErrors).length>0}>{isSubmitting ? 'Submitting...' : "Submit"}</button>

      </form>
    </div>
  )
}