


import { useUser } from '@clerk/clerk-react'
import { Container,Row,Col ,Alert, Card, Button} from 'react-bootstrap';

const AppItem = (app)=>{
    return (
        <Col key={1} xs={9} sm={6} md={4} lg={3} className="mb-4" style={{ maxWidth: '280px' }}>
            <Card className=" shadow-sm">
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="text-center mb-3">
                    {app.app.icon ? (
                        <app.icon size={64} color={app.app.color} />
                    ) : (
                        <img 
                        src={app.app.image} 
                        alt={`${app.app.name} logo`} 
                        style={{ width: '100px', height: '100px', objectFit: 'contain' }} 
                        />
                    )}
                    </div>
                    <Card.Title className="text-center">
                        {app.app.name}
                        {/* description as hint */}
                        
                    </Card.Title>
                    <div className='text-center'>
                    <p class="fs-6 fst-italic font-monospace">{app.app.description}</p>
                    </div>
                    <Button variant="outline-secondary" className="mt-0"
                        onClick={e=>{
                            window.location.href = app.app.url
                        }}
                        disabled={app.app.disabled}
                        >
                        {app.app.disabled ? 'Coming soon' : 'Launch'}
                    </Button>
                </Card.Body>
            </Card>
        </Col>)
}

const AppLauncher = () => {
    const {user, isLoaded} =useUser()

    const apps = [
        { 
            name: 'Attendance',
            image: "/images/undraw_Booking_re_gw4j.png",
            color: '#4ECDC4', description: 
            'Track & Manage attendance' ,
            url: "/batches",
            launch: false,
            disabled: false
        },
          { 
            name: 'LMS', 
            image: 'https://xcelerator.ninja/static/media/main-logo.ff98436a.png', 
            description: 'Access learning materials and courses' ,
            url: 'https://xcelerator.ninja',
            launch: true,
            disabled: false

          },
          { 
            name: 'Yaru', 
            image: '/images/undraw_Following_re_d5aa.png', 
            description: 'Find who is who @ the university' ,
            launch: false,
            disabled: true
          },
        //   { 
        //     name: 'Oota', 
        //     image: '/images/undraw_breakfast_psiw.png', 
        //     description: 'Food Memn from the canteen' ,
        //     launch: false,
        //     disabled: true
        //   }
        ];
      

    const supportedApps = (role)=>{
        switch(role){
            case 'teacher':
                return apps
            case 'student':
                return apps
            case 'admin':
                return apps
            default:
                return []
        }
    }

    const renderBasedOnRole = () => {
        switch(user?.publicMetadata?.role){
            case 'admin':
            window.location.href = "/admin-dashboard"; // Redirect admin to a specific page
            return null; // Prevent the rest of the apps from rendering for the admin
            case 'teacher':
            case 'student':
            // case 'teacher' || 'student' || 'admin':
                const apps = supportedApps(user?.publicMetadata?.role)
                if (apps.length > 0){
                    return <>{apps.map((app, index) => (
                        <AppItem key={index} app={app} />
                    ))}</>
                }
            default:
                return <>
                <Col>
                    <Alert key={"variant"} variant={"light"} className='text-center flex-d'>
                    No apps available for you yet! Please reachout to the admin.
                    </Alert>
                </Col>
                </>
        }
    }

    return (<>
        <Container className="mt-5">
            <Row className="text-center mb-4">
                <Col>
                    <h3 className="display-9 font-weight-bold">Hi, {user.fullName}</h3>
                    <p>Welcome to your launcher</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
            {renderBasedOnRole()}
            </Row>
        </Container>
    </>)
}


export default  AppLauncher;