
import { Navbar, Nav, Container,Row,Col } from 'react-bootstrap';
import { useUser,useAuth, SignedIn, UserButton, } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';

const NavHeader = () => {
    const {user, isLoaded} =useUser()
    const { isSignedIn } = useAuth()
    const navigate=useNavigate()

    useEffect(()=>{
        if(isLoaded && isSignedIn){
          const role= user && user.publicMetadata && user.publicMetadata?.role      
            if(window.location.pathname === '/'){
                navigate('/apps')
            }
          //TODO: add a endpoint to show no role assigned
        }
      },[isLoaded, isSignedIn])

    return<>
    <Navbar  expand="lg" style={{ backgroundColor: '#4f51a3', borderBottom: '1px solid #5d5fb1' }}>
        <Container fluid>
            <Navbar.Brand href="#home" className="d-flex align-items-center">
                <span style={{ fontSize: '0.75rem', color: '#b5b5b5', opacity: 1, marginRight: '0.5rem' }}>v1.0</span>
            </Navbar.Brand>
            <Nav className="me-auto">
                {/* <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#about">About</Nav.Link> */}
            </Nav>
            <Navbar.Brand href="/apps" className="rounded-circle">    
                <img
                    src="https://www.atriauniversity.edu.in/wp-content/themes/Pro%20-%20Child%20Theme/images/atria-logo2.png"
                    width="26"
                    height="30"
                    className="d-inline-block align-top rounded-circle me-3"
                    alt="Company Logo"
                />
            </Navbar.Brand>
            <Nav className="ms-auto">
                
                <SignedIn>
                    <UserButton />
                </SignedIn>
                
            </Nav>
        </Container>
    </Navbar>
    </>
}


export default NavHeader;