import React, { useEffect, useState } from "react";
import { useAuth } from '@clerk/clerk-react';
import { useParams, useNavigate } from 'react-router-dom';
import createAxiosInstance from "../utility/utils";
import { Spinner, Container, Breadcrumb, Col, Row, Table, Button } from "react-bootstrap";
import { ExternalLink } from "lucide-react";

export default function TeacherSessions() {
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const { isLoaded, isSignedIn, getToken } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const axiosInstance = createAxiosInstance(getToken);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      const fetchSessions = async () => {
        try {
          const response = await axiosInstance.get(`/api/admin/teacher/${teacherId}/sessions`);
          console.log('Full response object:', response.data);
          console.log('Teacher Attendance Array:', response.data.teacherAttendance);

          // Find the specific teacher by their teacherId
          const teacherData = response.data.teacherAttendance.find(teacher => teacher.teacherId === teacherId);
          
          if (teacherData && teacherData.sessions) {
            console.log('Sessions for teacher:', teacherData.sessions);
            setSessions(teacherData.sessions);
          } else {
            console.log('No sessions found for this teacher');
            setSessions([]); // Set to empty array if no sessions found
          }

          setIsLoading(false);
        } catch (err) {
          console.error('Error fetching sessions:', err);
          setIsLoading(false);
        }
      };

      fetchSessions();
    }
  }, [isLoaded, isSignedIn, teacherId]);

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    );
  }

  const handleSessionClick = async (session) => {
    try {
      const response = await axiosInstance.get(`/api/attendance/session/${session.sessionId}`);
      navigate('/attendance-records', {
        state: {
          batchName: session?.batch || 'unknown batch', 
          subjectName: session?.subject || 'unknown subject',
          date: session.date,
          time: session.time,
          sessionId: session.sessionId,
          attendanceRecords: response.data.attendanceRecords
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container className="mt-4">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Apps</Breadcrumb.Item>
        <Breadcrumb.Item href="/teachers">Teachers</Breadcrumb.Item>
        <Breadcrumb.Item active>Teacher Sessions</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col>
          <div className="border rounded-lg shadow-sm">
            <Table hover responsive className="mb-0">
              <thead className="bg-gray-50">
                <tr>
                  <th>Batch</th>
                  <th>Subject</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sessions && sessions.length > 0 ? (
                  sessions.map(session => (
                    <tr key={session.sessionId}>
                      <td>{session.batch || 'N/A'}</td>
                      <td>{session.subject || 'N/A'}</td>
                      <td>{session.date}</td>
                      <td>{session.time}</td>
                      <td>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleSessionClick(session)}
                        >
                          <ExternalLink className="w-4 h-4 mr-1" />
                          View
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No sessions found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
