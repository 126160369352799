import { useContext, useState } from "react"
import { BatchContext } from "../../contexts/batches-context"
import { useAuth } from "@clerk/clerk-react"
import createAxiosInstance from "../utility/utils"
import 'bootstrap/dist/css/bootstrap.min.css'


export default function AddBatch(){
  const {batchesDispatch}=useContext(BatchContext)

  const [formErrors, setFormErrors]=useState({})
  const [formData, setFormData]=useState({
    name:''
  })
  const [submitted, setSubmitted]=useState(false)
  const [isSubmitting, setIsSubmitting]=useState(false)
  const [serverErrors, setServerErrors]=useState('')
  const { getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)

  const validate=()=>{
    const errors={}
    if(!formData.name.trim()){
      errors.name='Name is required'
    }
    return errors
  }

  const resetForm=()=>{
    setFormData({
      name:''
    })
    setServerErrors('')
    setSubmitted(false)
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    const errors=validate()
    setFormErrors(errors)
    setSubmitted(true)

    if(Object.keys(errors).length===0){
      setIsSubmitting(true)
      try{
        const token= await getToken()
        console.log('user Token-', token)
        const response=await axiosInstance.post(`/api/batches`, formData)
        console.log(response.data)
        batchesDispatch({type: "ADD_BATCHES", payload: response.data})
        alert('batch added successfully')
        resetForm()
      }catch(err){
        const serverErrorMessage = err.response?.data?.errors || err.response?.data?.error|| 'Something went wrong'
        console.log(err)
        setServerErrors(serverErrorMessage)
      }finally{
        setIsSubmitting(false)
      }
    }
  }

  const handleChange=(e)=>{
    const {name, value}=e.target
    setFormData({
      ...formData,
      [name]:value
    })
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add Batch:</h2>
      {serverErrors && Array.isArray(serverErrors) ? (
        <div className="alert alert-danger">
          {serverErrors.map((error, index) => (
            <div key={index}>{error.msg || error.value || 'Unknown error'}</div>
          ))}
        </div>
        ) : (
        serverErrors && (
          <div className="alert alert-danger">
              {serverErrors}
            </div>
          )
        )}
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="name">Batch Name:</label>
          <input 
            type="text"
            className={`form-control ${submitted && formErrors.name ? 'is-invalid' : ''}`}
            value={formData.name}
            name='name'
            onChange={handleChange}
          />
        </div>
        {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}
        <button className="btn btn-primary" type="submit" disabled={isSubmitting || Object.keys(formErrors).length>0}>{isSubmitting ? 'Submitting...' : "Submit"}</button>
      </form>
    </div>
  )
}