import React from 'react'
import ListBatches from '../pages/list-batches'
import { Link } from 'react-router-dom'


export default function TeachersDashboard(props){
  return (
    <div>
      <h2>Teacher's Dashboard</h2>
      <div className="row">
        <div className="col-md-6">
          <ListBatches batches1={props.batches1} />
        </div>
        
        <div>
          <Link to='/teachers/mySession' className='btn btn-primary'> View Sessions</Link>
        </div>
      </div>
    </div>
  )
}