import { useContext, useEffect, useState } from "react"
import { StudentContext } from "../../contexts/studentContext"
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'

// import { BatchContext } from "../../contexts/batches-context"
import 'bootstrap/dist/css/bootstrap.min.css'
import { useAuth } from '@clerk/clerk-react'
import createAxiosInstance from "../utility/utils"

export default function ListStudents(){
  const {students, studentsDispatch}=useContext(StudentContext)
  const [batches1, setBatches]=useState('')
  const [isLoading, setIsLoading]=useState(true)
  // console.log(students)
  // const {batches}=useContext(BatchContext)
  // const [batches, setBatches]=useState({})
  const navigate=useNavigate()

  const { getToken } = useAuth()
  const axiosInstance=createAxiosInstance(getToken)

  useEffect(() => {
    (async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    })
      ();
  }, [])

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await axiosInstance.get(`/api/batches`); // Adjust URL to your batches endpoint
        const batchData = response.data.reduce((acc, batch) => {
          acc[batch._id] = batch.name;
          return acc;
        }, {});
        setBatches(batchData);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchBatches();
  }, []);

  const handleView = (studentId) => {
    navigate(`/attendance/${studentId}`);
  };

  const handleDelete=async(id)=>{
    const userConfirm=window.confirm("Are you sure?")
    if(userConfirm){
        try{
          // const response=await axios.delete(`http://localhost:3020/api/students/${id}`)
          // console.log(response.data)
          await axiosInstance.delete(`/api/students/${id}`)
          studentsDispatch({type:'REMOVE_STUDENT', payload: id})
        }catch(err){
          console.log(err.message)
        }
    }
  }

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    )
  }

  return(
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/admin-dashboard" >Home </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="/batches"> Batches </Breadcrumb.Item> */}
        <Breadcrumb.Item active>Students List</Breadcrumb.Item>
      </Breadcrumb>
    <div className="container mt-12">
      <h2 className="mb-4">Students List:</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th className="text-center">Sl. No.</th>
                <th className="text-center">Student Name</th>
                <th className="text-center">USN</th>
                <th className="text-center">Email</th>
                <th className="text-center">Batch</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {students?.data.length===0 ? <p>no students</p> : (
                students?.data.map((ele,i)=>(
                  <tr key={ele._id}>
                    <td className="text-center">{i+1}</td>
                    <td className="text-center">{ele.name}</td>
                    <td className="text-center">{ele.usnNumber}</td>
                    <td className="text-center">{ele.email}</td>
                    <td className="text-center">
                      {Array.isArray(ele.batch) ? (
                        ele.batch
                          .map((batchId) => batches1[batchId] || "Unknown Batch")
                          .filter((batchName) => batchName !== "Unknown Batch") // Filter out "Unknown Batch"
                          .join(", ") || "Unknown Batch" // Fallback in case all are unknown
                      ) : (
                        batches1[ele.batch] || "Unknown Batch"
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className='btn btn-info btn-sm mr-2'
                        onClick={() => handleView(ele._id)}
                      >
                        View Attendance
                      </button>
                      <button className="btn btn-danger btn-sm" onClick={()=>
                        handleDelete(ele._id)
                      }>Delete</button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
    </div>
    </Container>
  )
}