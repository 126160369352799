import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react'

import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';



// H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
H.init('2d1o314g', {
	serviceName: "frontend-app",
	tracingOrigins: true,
	networkRecording: {
		enabled: true,
		recordHeadersAndBody: true,
		urlBlocklist: [
			// insert full or partial urls that you don't want to record here
			// Out of the box, Highlight will not record these URLs (they can be safely removed):
			"https://www.googleapis.com/identitytoolkit",
			"https://securetoken.googleapis.com",
		],
	},
});

const PUBLISHABLE_KEY = process.env.REACT_APP_PUBLISHABLE_KEY;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl='/' >
        <App />
      </ClerkProvider>
    </ErrorBoundary>
  </BrowserRouter>
);