import { useContext, useState } from "react"
import { TeachersContext } from "../../contexts/teachers-context"
import createAxiosInstance from "../utility/utils"
import { useAuth } from "@clerk/clerk-react"

export default function AddAdmin(){
    const {teachersDispatch}=useContext(TeachersContext)
    const [formData, setFormData]=useState({
        name:'',
        employeeId:'',
        email:'',
        password:'',
        role:''
    })
    const [isSubmitting, setIsSubmitting]=useState(false)
    const [submitted, setSubmitted]=useState(false)
    const [formErrors, setFormErrors]=useState({})
    const [serverErrors, setServerErrors]=useState('')
    const { getToken } = useAuth()
    const axiosInstance=createAxiosInstance(getToken)
  

    const validate=()=>{
        const errors={}
        if(!formData.name.trim()){
          errors.name='Name is required'
        }
        // if(!formData.employeeId.trim()){
        //   errors.employeeId='Employee Id is required'
        // }
        if(!formData.email.trim()){
          errors.email='Email is required'
        }
        if(!formData.password.trim()){
          errors.password='Password is required'
        }
        if(!formData.role.trim()){
          errors.role='Role is required'
        }
        return errors
      }

    const resetForm=()=>{
    setFormData({
        name:'',
        employeeId:'',
        email:'',
        password:'',
        role:''
    })
    setServerErrors('')
}

    const handleChange=(e)=>{
        const {name, value}=e.target
        setFormData({
        ...formData,
        [name]:value
        })
    }
    const handleSubmit=async(e)=>{
        e.preventDefault()
        const errors=validate()
        setFormErrors(errors)
        setSubmitted(true)
    
        if(Object.keys(errors).length===0){
          setIsSubmitting(true)
          try{
            const response=await axiosInstance.post(`/api/users/create`, formData)
            console.log("createTeacher",response.data)
            teachersDispatch({type:'ADD_TEACHER', payload: response.data})
            alert('teacher added successfully')
            resetForm()
          }catch(err){
            console.log(err)
          }finally{
            setIsSubmitting(false)
          }
        }
    
      }
    
    return(
        <div>
            <h2>Add Admin:</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label>Admin Name:</label>
                    <input
                        type="text"
                        name="name"
                        className={`form-control ${submitted && formErrors.name ? 'is-invalid' : ''}`}
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                {submitted && formErrors.name && <div style={{color:'red'}}>Name is required</div>}

                <div className="form-group mb-3">
                <label htmlFor="email">Email:</label>
                <input 
                    type="email"
                    name="email"
                    className={`form-control ${submitted && formErrors.email ? 'is-invalid' : ''}`}
                    value={formData.email}
                    onChange={handleChange}
                />
                </div>
                {submitted && formErrors.email && <div style={{color:'red'}}>Email is required</div>}

                <div className="form-group mb-3">
                <label htmlFor="password">Password</label>
                <input 
                    type="password"
                    name="password"
                    className={`form-control ${submitted && formErrors.password ? 'is-invalid' : ''}`}
                    value={formData.password}
                    onChange={handleChange}
                />
                </div> 
                {submitted && formErrors.password && <div style={{color:'red'}}>Password is required</div>}

                <div className="form-group mb-3">
                <label htmlFor="role" className="form-label">Role</label>
                    <div>
                    
                    <input 
                        type="radio"
                        name="role"
                        value='admin'
                        checked={formData.role==='admin'}
                        onChange={handleChange}
                        className="form-check-input"
                        id='admin'
                    />
                    <label htmlFor="admin">Admin</label>
                    </div>

                </div>

                <button className="btn btn-primary" type="submit" disabled={isSubmitting || Object.keys(formErrors).length>0}>{isSubmitting ? 'Submitting...' : "Submit"}</button>
            </form>
        </div>
    )
}