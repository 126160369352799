import { useContext , useEffect, useState} from "react"
import { SubjectContext } from "../../contexts/subjects-context"

import 'bootstrap/dist/css/bootstrap.min.css'
import { useAuth } from "@clerk/clerk-react"
import createAxiosInstance from "../utility/utils"
import { Breadcrumb, Container } from "react-bootstrap"

export default function ListSubjects(props){
  const {subjects, subjectsDispatch}=useContext(SubjectContext)
  const [isLoading, setIsLoading] = useState(true)
  const {getToken}=useAuth()
  const axiosInstance=createAxiosInstance(getToken)

  useEffect(() => {
    (async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    })
      ();
  }, [])


  const handleRemove=async(id)=>{
    const userConfirm=window.confirm('Are you sure?')
    if(userConfirm){
      try{
        const response=await axiosInstance.delete(`api/subjects/${id}`)
        subjectsDispatch({type:'REMOVE_SUBJECT', payload: id})
      }catch(err){
        console.log(err)
      }
    }
  }
  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className='d-flex justify-content-center align-items-center'>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
          &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Breadcrumb>
      <Breadcrumb.Item href="/admin-dashboard" >Home </Breadcrumb.Item>
      <Breadcrumb.Item active>Subjects List</Breadcrumb.Item>
      </Breadcrumb>
      <div className='container mt-4'>
        <h2 className='mb-4'>List Subjects:</h2>
        <div className='table-responsive'>
          <table className='table table-striped table-bordered'>
            <thead className='thead-dark'>
              <tr>
                <th>Sl. No.</th>
                <th>Name</th>
                <th>batch</th>
              </tr>
            </thead>
            <tbody>          
              {subjects?.data?.length > 0 ? (
                subjects.data.map((subject, i) => (
                  <tr key={subject._id || i}>
                    <td>{i + 1}</td>
                    <td>{subject.name}</td>
                    <td>
                      {subject.batch.map((batchId, i)=>(
                        <span key={batchId}>
                          {props.batches1[batchId]}{i<subject.batch.length-1 ? ', ' : ''}
                        </span>
                      ))}
                    </td>
                    <td>
                      <button 
                        className="btn btn-danger btn-sm"
                        onClick={()=>handleRemove(subject._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No subjects found.</td>
                </tr>
              )}
          </tbody>
          </table>
        </div>    
      </div>
    </Container>
  )
}