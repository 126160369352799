export default function teachersReducer(state={data:[]}, action){
    switch(action.type){
      case "SET_TEACHERS":{
        return {...state, data:action.payload}
      }
      case "ADD_TEACHER":{
        return {...state, data:[...state.data, action.payload]}
      }
      case "REMOVE_TEACHER":{
        return {...state, data: state.data.filter(ele=>ele._id!==action.payload)}
      }
      default:{
        return state
      }
    }
}