import { useState } from "react";
import { useLocation } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Spinner, Container, Row, Col, Card, Table } from "react-bootstrap";
import { useEffect } from "react";

export default function AttendanceRecord(){

    const [presentCount, setPresentCount] = useState(0);
    const [absentCount, setAbsentCount] = useState(0);
    
    const location=useLocation()
    const {sessionId, batchName, subjectName, date, time, attendanceRecords}= location.state || {}
    const [isLoading, setIsLoading]= useState(true)
    
    useEffect(()=>{
        (async()=>{
            try{
                await new Promise(resolve=>setTimeout(resolve,1000))
                setIsLoading(false)

                const present = attendanceRecords.filter(record => record.attendanceStatus === 'present').length;
                const absent = attendanceRecords.filter(record => record.attendanceStatus !== 'present').length;

                setPresentCount(present);
                setAbsentCount(absent);
            }catch(err){
                console.log(err)
                setIsLoading(false)
            }
        })
        ();
    },[])

    if(isLoading){
        return (
            <Container className="d-flex justify-content-center align-items-center min-vh-100">
                <div className='d-flex justify-content-center align-items-center'>
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
                &nbsp;&nbsp;<p className="ml-3 mb-0 align-self-center">Loading... Please wait</p>
                </div>
            </Container>
        )
    }

return (
    <Container className="mt-4">
        <Breadcrumb>
            <Breadcrumb.Item href="/apps">Apps</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/admin/teacher/:teacherId/sessions">Teacher Sessions</Breadcrumb.Item> */}
            <Breadcrumb.Item href="/teachers">Teachers</Breadcrumb.Item>
            <Breadcrumb.Item active>Attendance Report</Breadcrumb.Item>
            {/* <Breadcrumb.Item active>{sessionId}</Breadcrumb.Item> */}
        </Breadcrumb>
        <Row className="text-center mb-2">
            <Col>
            <img 
            src={"/images/undraw_Detailed_analysis_re_tk6j.png"} 
            // alt={`${app.app.name} logo`} 
            style={{ width: '250px', height: '200px', objectFit: 'contain' }} 
            />
            </Col>
        </Row>
        {/* <Spinner animation="border" /> */}
        <Row className="text-center mb-2">
            <Col>
           <Card>
           {sessionId && (
            <>
                <Table striped bordered hover responsive>
                <tbody>
                    <tr>
                        <td>Batch</td>
                        <td>{batchName}</td>                    
                    </tr>
                    <tr>
                        <td>Subject</td>
                        <td>{subjectName}</td>                    
                    </tr>
                    <tr>
                        <td>Date</td>
                        <td>{date}</td>                    
                    </tr>
                    <tr>
                        <td>Time</td>
                        <td>{time}</td>                    
                    </tr>
                    <tr>
                        <td>Total Present</td>
                        <td>{presentCount}</td>
                    </tr>
                    <tr>
                        <td>Total Absent</td>
                        <td>{absentCount}</td>
                    </tr>
                </tbody>
                </Table>
                </>
      )}
           </Card>
            </Col>
        </Row>
       
       
        <Table striped bordered hover responsive>
        <thead className="thead-dark">
                <tr>
                    <th colSpan={2}>Student Attendance List</th>
                   
                </tr>
            </thead>
            <thead className="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                </tr>
            </thead>
            {/* <tbody>
                {attendanceRecords.map((record, i)=>(
                    <tr key={i}>
                        <td>{record.student.name}</td>
                        <td 
                        className={record.attendanceStatus==='present' ? 'bg-success' : 'bg-danger'}
                        style={{color: 'white' , fontWeight: 'bold'}}
                        >{(record.attendanceStatus+"").toLocaleUpperCase()}</td>
                    </tr>
                ))}
            </tbody> */}
            <tbody>
                {attendanceRecords.map((record, i) => (
                    <tr key={i}>
                    <td>{record.student.name}</td>
                    <td
                        style={{
                        backgroundColor: record.attendanceStatus === 'present' ? 'MediumSeaGreen' : 'Tomato', // Custom green for 'present' and red for 'absent'
                        color: 'white',
                        fontWeight: 'bold',
                        }}
                    >
                        {(record.attendanceStatus + "").toLocaleUpperCase()}
                    </td>
                    </tr>
                ))}
                </tbody>

        </Table>
        
    </Container>
)}

