import React from "react";
import AddStudent from "../pages/createStudent";
import ListStudents from "../pages/list-students";
import ListBatches from "../pages/list-batches";
import AddBatch from "../pages/create-batches";
import ListTeachers from "../pages/list-teachers";
import AddTeacher from "../pages/create-teacher";
import ListSubjects from "../pages/list-subjects";
import AddSubject from "../pages/create-subjects";
import ListAttendance from "../pages/list-attendance";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container'
import AddAdmin from "../pages/create-admin";
// import BatchwiseStudentsList from "../pages/students-batchwise";
// import 'bootstrap/dist/css/bootstrap.min.css'

export default function AdminsDashboard(props){
  
  return(
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Apps</Breadcrumb.Item>
        <Breadcrumb.Item href="/teachers">Teachers</Breadcrumb.Item>
        <Breadcrumb.Item href="/students-data">Students</Breadcrumb.Item>
        <Breadcrumb.Item href="/batches">Batches</Breadcrumb.Item>
        <Breadcrumb.Item href='/subjects'>Subjects</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <AddAdmin />
          </div>
        </div>    
        <div className="row">
          <div className="col-md-6">
            <AddBatch />
          </div>
          <div className="col-md-6">
            <AddStudent batches1={props.batches1}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <AddSubject />
          </div>
          <div className="col-md-7">
            <AddTeacher />        
          </div>
        </div>
      </div>
    </Container>
  )
}