export default function subjectsReducer(state={data:[]}, action){
  switch(action.type){
    case "SET_SUBJECTS":{
      return {...state, data: action.payload}
    }
    case "ADD_SUBJECT":{
      return {...state, data:[...state.data, action.payload]}
    }
    case "REMOVE_SUBJECT":{
      return {...state, data:state.data.filter(ele=>ele._id!==action.payload)}
    }
    case "SET_SUBJECT_BATCHWISE":{
      return {...state, data:action.payload}
    }
    default:{
      return state
    }
  }
}